import React, { useState } from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi'; // Tyndere pil-ikon
import { useNavigate } from 'react-router-dom'; // Importer useNavigate
import '../Styles/CTAButton.css';

function CTAButton({ text, navigateTo }) { // Tilføj navigateTo som prop
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate(); // Initialiser useNavigate

  return (
    <button 
      className="cta-button"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => navigate(navigateTo)} // Tilføj onClick-hændelse
    >
      <span>{text}</span>
      {isHovered && <HiArrowNarrowRight className="arrow-icon" />}
    </button>
  );
}

export default CTAButton;
