import React, { useState, useEffect, useRef } from 'react';
import '../Styles/Kontakt.css';
import CTAButton from '../Components/CTAButton';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Kontakt() {
  const [formData, setFormData] = useState({
    navn: '',
    virksomhed: '',
    telefonnummer: '',
    email: '',
    besked: ''
  });

  const [errors, setErrors] = useState({});

  const heroBackgroundRef = useRef(null);

  const validateField = (name, value) => {
    switch (name) {
      case 'navn':
        return value.trim() === '' ? 'Lad os vide hvad vi skal kalde dig' : '';
      case 'virksomhed':
        return value.trim() === '' ? 'Hvilken virksomhed har du brug for hjælp til?' : '';
      case 'telefonnummer':
        return value.trim() === '' ? 'Venligst indtast dit telefonnummer' : 
               !/^[0-9]{8}$/.test(value) ? 'Skriv dit telefonnummer så vi ved hvordan vi kan komme i kontakt' : '';
      case 'email':
        return value.trim() === '' ? 'Venligst indtast din e-mailadresse' : 
               !/\S+@\S+\.\S+/.test(value) ? 'Lad os have din e-mail så vi kan komme i kontakt' : '';
      case 'besked':
        return value.trim() === '' ? 'Skriv en besked så vi ved hvad du har brug for' : '';
      default:
        return '';
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === 'telefonnummer') {
      newValue = value.replace(/[^0-9]/g, '').slice(0, 8);
    }

    setFormData({ ...formData, [name]: newValue });
    setErrors({ ...errors, [name]: '' });

    if (e.target.tagName.toLowerCase() === 'textarea') {
      adjustTextareaHeight(e.target);
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const adjustTextareaHeight = (element) => {
    if (element.value) {
      element.style.height = 'auto';
      element.style.height = Math.min(element.scrollHeight, 200) + 'px';
      element.style.overflowY = 'auto';
    } else {
      element.style.height = '40px';
      element.style.overflowY = 'hidden';
    }
  };

  useEffect(() => {
    const textarea = document.querySelector('textarea');
    if (textarea) {
      adjustTextareaHeight(textarea);
    }
  }, [formData.besked]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      if (heroBackgroundRef.current) {
        heroBackgroundRef.current.style.transform = `translateY(${scrollPosition * 0.5}px)`;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    let hasErrors = false;

    Object.keys(formData).forEach(key => {
      const error = validateField(key, formData[key]);
      if (error) {
        newErrors[key] = error;
        hasErrors = true;
      }
    });

    if (hasErrors) {
      setErrors(newErrors);
      scrollToError();
      return;
    }
    
    try {
      await addDoc(collection(db, 'mail'), {
        to: ['info@parlabranding.dk'],
        message: {
          subject: 'Ny besked fra kontaktformular',
          html: `
            <h2>Ny besked fra kontaktformular</h2>
            <p><strong>Navn:</strong> ${formData.navn}</p>
            <p><strong>Virksomhed:</strong> ${formData.virksomhed}</p>
            <p><strong>Telefonnummer:</strong> ${formData.telefonnummer}</p>
            <p><strong>E-mail:</strong> ${formData.email}</p>
            <p><strong>Besked:</strong> ${formData.besked}</p>
          `
        }
      });
      alert('Tak for din besked. Vi vender tilbage hurtigst muligt.');
      setFormData({ navn: '', virksomhed: '', telefonnummer: '', email: '', besked: '' });
      setErrors({});
    } catch (error) {
      console.error('Fejl ved afsendelse af besked:', error);
      alert('Der opstod en fejl ved afsendelse af beskeden. Prøv venligst igen senere.');
    }
  };

  const scrollToError = () => {
    const firstErrorField = document.querySelector('.error-message');
    if (firstErrorField) {
      firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <div className="kontakt-page">
      <section className="kontakt-hero">
        <div className="kontakt-hero-background" ref={heroBackgroundRef}></div>
        <h1>Kontakt os</h1>
      </section>
      <div className="kontakt-content">
        <div className="kontakt-info">
          <h2>Lad os snakke</h2>
          <p>Vi er klar til at hjælpe dig med dine brandingbehov. Udfyld formularen, så vender vi tilbage hurtigst muligt.</p>
          <div className="kontakt-detaljer">
            <p>
              <FontAwesomeIcon icon={faPhone} className="kontakt-icon" />
              <a href="tel:+4536170000">+45 36 17 00 00</a>
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} className="kontakt-icon" />
              <a href="mailto:kontakt@parlabranding.dk">kontakt@parlabranding.dk</a>
            </p>
          </div>
        </div>
        <form className="kontakt-form" onSubmit={handleSubmit} noValidate>
          <div className="form-group">
            <input type="text" id="navn" name="navn" placeholder=" " value={formData.navn} onChange={handleChange} onBlur={handleBlur} />
            <label htmlFor="navn">Navn *</label>
            {errors.navn && <span className="error-message">{errors.navn}</span>}
          </div>
          <div className="form-group">
            <input type="text" id="virksomhed" name="virksomhed" placeholder=" " value={formData.virksomhed} onChange={handleChange} onBlur={handleBlur} />
            <label htmlFor="virksomhed">Virksomhed *</label>
            {errors.virksomhed && <span className="error-message">{errors.virksomhed}</span>}
          </div>
          <div className="form-group">
            <input type="tel" id="telefonnummer" name="telefonnummer" placeholder=" " value={formData.telefonnummer} onChange={handleChange} onBlur={handleBlur} />
            <label htmlFor="telefonnummer">Telefonnummer *</label>
            {errors.telefonnummer && <span className="error-message">{errors.telefonnummer}</span>}
          </div>
          <div className="form-group">
            <input type="email" id="email" name="email" placeholder=" " value={formData.email} onChange={handleChange} onBlur={handleBlur} />
            <label htmlFor="email">E-mail *</label>
            {errors.email && <span className="error-message">{errors.email}</span>}
          </div>
          <div className="form-group">
            <textarea 
              id="besked" 
              name="besked" 
              placeholder=" " 
              value={formData.besked} 
              onChange={handleChange}
              onBlur={handleBlur}
              onInput={(e) => adjustTextareaHeight(e.target)}
              onFocus={(e) => {
                if (!e.target.value) {
                  e.target.style.height = '100px';
                  e.target.style.overflowY = 'auto';
                }
              }}
            ></textarea>
            <label htmlFor="besked">Besked *</label>
            {errors.besked && <span className="error-message">{errors.besked}</span>}
          </div>
          <CTAButton text="Send besked" type="submit" />
        </form>
      </div>
    </div>
  );
}

export default Kontakt;
