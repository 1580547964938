import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Forside from './Pages/Forside';
import Cases from './Pages/Cases';
import Om from './Pages/Om';
import SEO from './Pages/SEO';
import GoogleAds from './Pages/GoogleAds';
import CaseDetails from './Components/CaseDetails';
import AIChatbot from './Pages/AIChatbot';
import Kontakt from './Pages/Kontakt';
import Admin from './Pages/Admin'; // Tilføj denne linje
import ScrollToTop from './Components/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Forside />} />
          <Route path="/cases" element={<Cases />} />
          <Route path="/case/:id" element={<CaseDetails />} />
          <Route path="/om" element={<Om />} />
          <Route path="/ai-chatbot" element={<AIChatbot />} />
          <Route path="/seo" element={<SEO />} />
          <Route path="/google-ads" element={<GoogleAds />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/admin" element={<Admin />} /> // Tilføj denne linje
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
