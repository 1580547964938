import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../Styles/Header.css';
import CTAButton from './CTAButton';
import logoImage from '../images/PBBilleder/PBLogo.png';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = isMenuOpen ? 'auto' : 'hidden';
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Luk menuen og gendan scroll når siden ændres
    setIsMenuOpen(false);
    document.body.style.overflow = 'auto';
  }, [location]);

  return (
    <header className={`header ${isScrolled ? 'header-scrolled' : ''}`}>
      <div className="header-content">
        <div className="header-logo">
          <NavLink to="/">
            <img src={logoImage} alt="Parla Branding Logo" className="header-logo-image" />
          </NavLink>
        </div>
        <nav className={`header-nav-menu ${isMenuOpen ? 'header-open' : ''}`}>
          <ul>
            <li><NavLink to="/" end onClick={toggleMenu}>Forside</NavLink></li>
            <li><NavLink to="/cases" onClick={toggleMenu}>Cases</NavLink></li>
            <li><NavLink to="/om" onClick={toggleMenu}>Om os</NavLink></li>
            <li className="header-dropdown">
              <span>Ydelser</span>
              <ul className="header-dropdown-menu">
                <li>
                  <NavLink to="/ai-chatbot" onClick={toggleMenu}>
                    <i className="fas fa-robot"></i>
                    <span>AI Chatbot</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/seo" onClick={toggleMenu}>
                    <i className="fas fa-search"></i>
                    <span>SEO</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/google-ads" onClick={toggleMenu}>
                    <i className="fab fa-google"></i>
                    <span>Google Ads</span>
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <div className="header-cta-container">
          <NavLink to="/kontakt">
            <CTAButton text="Kontakt os" />
          </NavLink>
        </div>
        <button className={`header-menu-toggle ${isMenuOpen ? 'header-open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </header>
  );
}

export default Header;